import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Reveal, { Fade } from 'react-awesome-reveal'
import { keyframes } from '@emotion/react'
import { Container, Title } from '@UI'
import * as styles from './pickup.module.scss'

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale(1.05);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`

const Pickup = () => {
  return (
    <section className={styles.intro}>
      <Container section className={styles.textContainer}>
        <Container wrapper textBlock className={styles.text}>
          <Fade triggerOnce>
            <Title>Pick-up and Drop-off Services Available</Title>
            <p>
              Too busy to leave the office? Cody Bookkeeping offers pick-up and
              drop-off services to local clients. Schedule a time that is
              convenient for you, and your paper work will be picked up and
              accurate financial reports will be delivered directly to your
              office.
            </p>
          </Fade>
        </Container>
      </Container>
      <div className={styles.imageContainer}>
        <Reveal
          triggerOnce
          keyframes={customAnimation}
          className={styles.image}
          fraction={0.2}
        >
          <StaticImage
            src="../../../assets/images/cody-bookkeeping-cody-wyoming.jpg"
            alt="Buffalo Bill statue in Cody, Wyoming."
            layout="fullWidth"
            className={styles.image}
            style={{ position: 'absolute' }}
            imgStyle={{ objectPosition: 'center' }}
            placeholder="blurred"
            loading="lazy"
          />
        </Reveal>
      </div>
    </section>
  )
}

export default Pickup
