import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { Container, Title } from '@UI'
import Form from './Form'
import Map from './Map'
import * as styles from './contact.module.scss'

const Contact = () => (
  <Container wrapperSmOnMd isSection section id="contact">
    <Fade triggerOnce>
      <Container
        wrapperSm
        noPadding
        textCenter
        textBlock
        className={styles.textContainer}
      >
        <Title>Let's Get in Touch</Title>
        <p>
          If you have any questions or would like to schedule a free
          consultation, please fill out the form below or email directly. All
          inquiries will be answered as soon as possible.
        </p>
      </Container>
    </Fade>

    <Fade triggerOnce>
      <div className={styles.contact}>
        <Map />
        <div className={styles.formContainer}>
          <Form />
        </div>
      </div>
    </Fade>
  </Container>
)

export default Contact
