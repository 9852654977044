import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import { Layout } from '@global'
import {
  Hero,
  Intro,
  Services,
  Pickup,
  Benefits,
  Cta,
  Testimonials,
  Contact,
} from '@home'

const IndexPage = ({ data }) => (
  <Layout>
    <Helmet>
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "FinancialService",
          "name": "Cody Bookkeeping",
          "hasmap": "googlemaps.com=entry",
          "image": "https://www.codybookkeeping.net${data.file.publicURL}",
          "@id": "https://www.codybookkeeping.net",
          "url": "https://www.codybookkeeping.net",
          "telephone": "3072729088",
          "priceRange": "$$",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "1737 Sheridan Ave Suite 302",
            "addressLocality": "Cody",
            "addressRegion": "WY",
            "postalCode": "82414",
            "addressCountry": "US"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 44.5226839,
            "longitude": -109.07669609999999
          }
        }
      `}</script>
    </Helmet>
    <Hero />
    <Intro />
    <Benefits />
    <Cta />
    <Services />
    <Pickup />
    <Testimonials />
    <Contact />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query BannerImage {
    file(name: { eq: "cody-bookkeeping-banner" }) {
      publicURL
    }
  }
`
