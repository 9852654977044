import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Reveal, { Fade } from 'react-awesome-reveal'
import { keyframes } from '@emotion/react'
import { Button, Container, Title } from '@UI'
import * as styles from './intro.module.scss'

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale(1.05);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`

const Intro = () => {
  return (
    <section className={styles.intro} id="about">
      <div className={styles.imageContainer}>
        <Reveal
          triggerOnce
          keyframes={customAnimation}
          className={styles.image}
          fraction={0.2}
        >
          <StaticImage
            src="../../../assets/images/cody-wyoming-bookkeeping-payroll.jpg"
            alt=""
            layout="fullWidth"
            className={styles.image}
            style={{ position: 'absolute' }}
            imgStyle={{ objectPosition: 'center left' }}
            placeholder="blurred"
            loading="lazy"
          />
        </Reveal>
      </div>

      <Container section className={styles.textContainer}>
        <Fade triggerOnce>
          <Container wrapper textBlock className={styles.text}>
            <Title>Local Bookkeeping Services in Cody, Wyoming</Title>
            <p>
              Running a business is time consuming. Why not give yourself one
              less thing to worry about by outsourcing your bookkeeping and
              payroll services?
            </p>
            <p>
              With over 20 years experience, Cody Bookkeeping provides accurate,
              timely financial services to keep your business running smoothly
              without the headaches.
            </p>
            <Button to="/#services">View Services</Button>
          </Container>
        </Fade>
      </Container>
    </section>
  )
}

export default Intro
