import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { Container, Icon, Title } from '@UI'
import { Clock, Bills, Target } from '@images/icons'
import * as styles from './benefits.module.scss'

const Benefits = () => {
  const data = [
    {
      title: 'Reclaim Your Time',
      body: "You'll have more time and energy to grow a successful business.",
      icon: <Clock />,
    },
    {
      title: 'Get Accurate Results',
      body: 'Eliminate errors and ensure your financial reports are accurate.',
      icon: <Target />,
    },
    {
      title: 'Save Money',
      body: 'Keep your overhead low by eliminating the need to train a new employee.',
      icon: <Bills />,
    },
  ]
  return (
    <Container isSection section textCenter>
      <Container className={styles.titleContainer}>
        <Fade triggerOnce>
          <Title center>
            Spend Less Time Managing Expenses and More Time Running Your
            Business
          </Title>
        </Fade>
      </Container>

      <Fade triggerOnce>
        <Container wrapper noPadding className={styles.iconsContainer}>
          {data.map(({ title, body, icon }) => (
            <div className={styles.card} key={title}>
              <Icon>{icon}</Icon>
              <Title tag="h3" className={styles.cardTitle}>
                {title}
              </Title>
              <p>{body}</p>
            </div>
          ))}
        </Container>
      </Fade>
    </Container>
  )
}

export default Benefits
