import React from 'react'
import { Button } from '@UI'
import * as styles from './form.module.scss'

const Form = () => {
  return (
    <form
      name="contact-page"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/thank-you?no-cache=1"
      className={styles.form}
    >
      <input type="hidden" name="form-name" value="contact-page" />
      <input type="hidden" name="bot-field" />

      <div className={styles.inputContainer}>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" required />
      </div>

      <div className={styles.inputContainer}>
        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" required />
      </div>

      <div className={styles.inputContainer}>
        <label htmlFor="phone">Phone</label>
        <input type="tel" id="phone" name="phone" required />
      </div>

      <div className={styles.inputContainer}>
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" cols="30" rows="10" required />
      </div>

      <Button type="submit" name="submit" id="submit">
        Send Email
      </Button>
    </form>
  )
}

export default Form
