import React, { useEffect, useState } from 'react'
import Reveal, { Fade } from 'react-awesome-reveal'
import { Button, Container, Icon, Title } from '@UI'
import { Check } from '@images/icons'
import * as styles from './services.module.scss'
import { keyframes } from '@emotion/react'

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Services = () => {
  const data = [
    {
      title: 'Payroll Services',
      services: [
        'Payroll',
        'Payroll Tax Reporting',
        'Quarterly Reports',
        'Employee W-2 Preparation',
        'Direct Deposits',
      ],
    },
    {
      title: 'Bookkeeping Services',
      services: [
        `1099s`,
        'Accounts Payable',
        'Accounts Receivable',
        'Bank Reconciliation',
        'Financial Statements',
        'Sales Tax Reports',
        'Year-end Reports for Tax Accountant',
      ],
    },
  ]

  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  return (
    <div className={styles.gap}>
      <Container isSection section className={styles.gray} id="services">
        <Container className={styles.wrapper} wrapper>
          <Fade triggerOnce>
            <div className={styles.flex}>
              <Container textBlock className={styles.textContainer}>
                <Title>Get the Best Fit for Your Needs and Your Budget</Title>
                <p>
                  No two businesses are the same, so each plan is crafted to fit
                  your company's unique requirements.
                </p>
                <p>
                  Cody Bookkeeping will work with you to ensure you get all the
                  services you need without wasting money on ones you don't.
                </p>
                <Button cta to="/#contact">
                  Get your free quote
                </Button>
              </Container>

              <div className={styles.listsContainer}>
                {data.map(({ title, services }, i) => (
                  <Reveal
                    triggerOnce
                    keyframes={customAnimation}
                    style={{ flex: '1', alignSelf: 'stretch' }}
                    delay={windowWidth > 600 ? 250 * i : 0}
                    key={title}
                  >
                    <div className={styles.listContainer}>
                      <Title tag="h3">{title}</Title>
                      <ul className={styles.list}>
                        {services.map((item) => (
                          <li key={item}>
                            <Icon sm>
                              <Check />
                            </Icon>
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Reveal>
                ))}
              </div>
            </div>
          </Fade>
        </Container>
      </Container>
    </div>
  )
}

export default Services
