import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Button, Container } from '@UI'
import * as styles from './hero.module.scss'

const Hero = () => (
  <div className={styles.hero}>
    <StaticImage
      src="../../../assets/images/cody-wyoming-financial-services-small-businesses-min.jpg"
      alt=""
      loading="eager"
      className={styles.bgImage}
      layout="fullWidth"
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    />

    <Container wrapper id="home" className={styles.inner}>
      <Container wrapperSm className={styles.content}>
        <h1>
          Affordable Bookkeeping &amp; Payroll Services for Small Businesses
        </h1>
        <p>
          Get more time to grow your business with one-on-one, personalized
          financial services tailored to your business's unique needs.
        </p>
        <Button to="/#contact" cta>
          Get a Free Consultation
        </Button>
      </Container>
    </Container>
  </div>
)

export default Hero
