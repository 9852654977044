import React, { useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Parallax } from 'react-parallax'
import classnames from 'classnames'
import { Container, Title } from '@UI'
import { ArrowLeft, ArrowRight } from '@images/icons'
import * as styles from './testimonials.module.scss'

import bgImage from '../../../assets/images/cody-bookkeeping-small-business-bookkeeping-payroll.webp'

const Testimonials = () => {
  const data = [
    {
      body: `LaLa is professional and accurate with my monthly financial statements, accounts payable and payroll. Her services are prompt and with a smile. I would recommend her for your bookkeeping needs.`,
      author: 'Harold Kaiser, Jr.',
      company: 'President, Pizza On The Run, Inc.',
      url: 'http://pizzaontherun.com',
    },
    {
      body: `LaLa at Cody Bookkeeping has done an amazing job for our business. She is extremely dedicated, prompt, always willing to go above and beyond for her customers.  Her service and skills are exemplary and we would recommend them to anyone looking for professional services of this kind.`,
      author: 'Jennifer Bragg',
      company: 'Bragg Plumbing & Heating',
      url: 'https://www.facebook.com/pages/category/Plumbing-Service/Bragg-Plumbing-Heating-Inc-452240808240289/',
    },
  ]

  const [index, setIndex] = useState(0)

  const handleIndexChange = (newIndex) => {
    if (newIndex > data.length - 1) {
      setIndex(0)
    } else if (newIndex < 0) {
      setIndex(data.length - 1)
    } else {
      setIndex(newIndex)
    }
  }

  return (
    <div className={styles.gap} id="testimonials">
      <Parallax bgImage={bgImage} bgImageAlt="" strength={200}>
        <Container isSection wrapperSmOnMd bgDark>
          <Fade triggerOnce>
            <div className={styles.flex}>
              <Title className={styles.title}>
                See What Clients Are Saying
              </Title>

              <div className={styles.testimonialContainer}>
                {data.map(({ body, author, company, url }, i) => (
                  <div
                    key={author}
                    className={classnames(
                      styles.testimonial,
                      i === index && styles.active
                    )}
                  >
                    <p className={styles.testimonialBody}>{body}</p>
                    <p className={styles.testimonialAuthor}>{author}</p>
                    {company && (
                      <small className={styles.testimonialCompany}>
                        {url ? (
                          <a href={url} target="_blank" rel="noreferrer">
                            {company}
                          </a>
                        ) : (
                          { company }
                        )}
                      </small>
                    )}
                  </div>
                ))}

                <div className={styles.buttonsContainer}>
                  <button
                    onClick={() => handleIndexChange(index - 1)}
                    className={styles.button}
                    aria-label="Previous testimonial"
                  >
                    <ArrowLeft />
                  </button>
                  {data.map((_, i) => (
                    <div
                      key={i}
                      className={classnames(
                        styles.dot,
                        i === index && styles.activeDot
                      )}
                    />
                  ))}
                  <button
                    onClick={() => handleIndexChange(index + 1)}
                    className={styles.button}
                    aria-label="Next testimonial"
                  >
                    <ArrowRight />
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Container>
      </Parallax>
    </div>
  )
}

export default Testimonials
