import React from 'react'
import { Parallax } from 'react-parallax'
import { Fade } from 'react-awesome-reveal'
import { Button, Container, Title } from '@UI'
import * as styles from './cta.module.scss'

import statue from '../../../assets/images/cody-bookkeeping-payroll-services-cody-wy.webp'

const Cta = () => (
  <Parallax bgImage={statue} bgImageAlt="" strength={200}>
    <section className={styles.container}>
      <Fade triggerOnce>
        <Container wrapper bgDark textBlock textCenter>
          <Title className={styles.title} fullWidth>
            Ready To Find Out What Cody Bookkeeping Can Do for You?
          </Title>
          <p className={styles.body}>
            Don't hesitate to reach out! Get a free, no-obligation consultation
            to find out if we're a good fit.
          </p>
          <Button to="/#contact" cta>
            Schedule a Free Consultation
          </Button>
        </Container>
      </Fade>
    </section>
  </Parallax>
)

export default Cta
