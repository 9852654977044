import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Button, Icon } from '@UI'
import { Phone, MapPin, Email } from '@images/icons'
import * as styles from './map.module.scss'

const Map = () => {
  return (
    <div className={styles.map}>
      <StaticImage
        src="../../../assets/images/map-of-cody-wy.png"
        alt=""
        layout="fullWidth"
        className={styles.image}
        style={{ position: 'absolute', opacity: 0.15 }}
        imgStyle={{ objectPosition: 'center' }}
        loading="lazy"
        placeholder="blurred"
      />

      <div className={styles.content}>
        <div className={styles.linkContainer}>
          <Icon md>
            <Phone />
          </Icon>
          <a href="tel:3072729088">(307) 272-9088</a>
        </div>

        <div className={styles.linkContainer}>
          <Icon md>
            <MapPin />
          </Icon>
          <div>
            <p>1737 Sheridan Ave Suite 302</p>
            <p>Cody, WY 82414</p>
          </div>
        </div>

        <div className={styles.linkContainer}>
          <Icon md>
            <Email />
          </Icon>
          <a href="mailto:info@codybookkeeping.net">info@codybookkeeping.net</a>
        </div>

        <div>
          <Button
            href="https://www.google.com/maps/place/1737+Sheridan+Ave,+Cody,+WY+82414/@44.5263473,-109.0549908,17z/data=!3m1!4b1!4m5!3m4!1s0x534c1ec8fc95e1f1:0x930432c0de648b2c!8m2!3d44.5263435!4d-109.0527968"
            cta
          >
            Open in Google Maps
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Map
