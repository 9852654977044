// extracted by mini-css-extract-plugin
export var active = "testimonials-module--active--Bh9nh";
export var activeDot = "testimonials-module--activeDot--B42ii";
export var button = "testimonials-module--button--hMjuq";
export var buttonsContainer = "testimonials-module--buttonsContainer--0jyIw";
export var dot = "testimonials-module--dot--b-ZYe";
export var fadeIn = "testimonials-module--fadeIn--eLF2Y";
export var flex = "testimonials-module--flex--dHKdY";
export var gap = "testimonials-module--gap--MeHRD";
export var testimonial = "testimonials-module--testimonial--iB0Hg";
export var testimonialAuthor = "testimonials-module--testimonialAuthor--PQXWf";
export var testimonialBody = "testimonials-module--testimonialBody--HKI+g";
export var testimonialCompany = "testimonials-module--testimonialCompany--PUrd9";
export var testimonialContainer = "testimonials-module--testimonialContainer--ddcQ9";
export var title = "testimonials-module--title--AFwQb";